define("discourse/plugins/delete-broken-link-post-revision/discourse/initializers/initializer-name", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/helpers"], function (_exports, _pluginApi, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "initializer-name",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.0", api => {
        //get all settings for site
        const siteSettings = (0, _helpers.helperContext)().siteSettings;
        //doing something
      });
    }
  };
});