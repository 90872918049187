define("discourse/plugins/delete-broken-link-post-revision/discourse/controllers/name", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    reset() {
      this.setProperties({});
    },
    actions: {
      //function using in /templates/name.hbs
    }
  }, [["method", "reset", [(0, _decorators.on)("init")]]]));
});