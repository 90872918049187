define("discourse/plugins/delete-broken-link-post-revision/discourse/templates/name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{ i18n 'name.title' }}</h1>
  */
  {
    "id": "lYQz+hup",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,1],[\"name.title\"],null]],[13]],[],false,[\"h1\",\"i18n\"]]",
    "moduleName": "discourse/plugins/delete-broken-link-post-revision/discourse/templates/name.hbs",
    "isStrictMode": false
  });
});