define("discourse/plugins/delete-broken-link-post-revision/discourse/name-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * Links the path `/name` to a route named `name`. Named like this, a
   * route with the same name needs to be created in the `routes` directory.
   */

  function _default() {
    this.route('name', {
      path: '/name'
    });
  }
});