define("discourse/plugins/delete-broken-link-post-revision/discourse/routes/name", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/name` as defined in `../name-route-map.js.es6`.
   */
  var _default = _exports.default = _discourse.default.extend({
    renderTemplate() {
      // Renders the template `../templates/name.hbs`
      this.render('name');
    }
  });
});